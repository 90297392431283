






import Vue from 'vue'
import Component from 'vue-class-component'
import CommonPicklDetails from '@/components/common/PicklDetails.vue'
import DeletePicklPopup from '@/components/SAdmin/DeletePicklPopup.vue'

@Component({
  components: {
    CommonPicklDetails,
    DeletePicklPopup
  }
})
export default class PicklDetails extends Vue {
  public pickl: object= {}
  public userRole: any = 'staff'
  public userUrl: any = 'staff'
  public isBrand: boolean = false

  mounted () {
    this.getPicklDetails()
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else if (this.userRole === 'brand') {
      this.isBrand = true
      this.userUrl = 'brand'
    }
  }

  redirectToPicklsPage () {
    this.$router.go(-1)
  }
  getPicklDetails () {
    this.$store.dispatch('getPicklDetails', { picklId: this.$route.params.picklId }).then((response) => {
      this.pickl = response.response.body
    })
  }
}
