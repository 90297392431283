var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pickl-details" },
    [
      _c("CommonPicklDetails", {
        attrs: {
          popupId: "pickl-details-popup",
          brand: _vm.isBrand,
          pickl: _vm.pickl
        },
        on: {
          reversedRejection: _vm.getPicklDetails,
          statusUpdated: _vm.redirectToPicklsPage,
          updatePicklDetails: _vm.getPicklDetails
        }
      }),
      _c("DeletePicklPopup", {
        attrs: { popupId: "deletepicklpopup", picklId: _vm.pickl.id },
        on: { picklDeleted: _vm.redirectToPicklsPage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }